import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL || (process.env.NODE_ENV === 'development' ? 'https://collecare.wemade.dev/api/v1/' : 'http://collicare-api.pinion.ph/api/v1/'),
});

instance.interceptors.request.use((config) => {
  const token = localStorage['collicare-user-details']
    ? JSON.parse(localStorage['collicare-user-details']).details.token
    : '';
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${token}`;
  config.headers.Accept = 'application/json';
  config.headers['Content-Type'] = 'application/json';
  return config;
}, (error) => Promise.reject(error));

const rejectedResponse = (error) => (error.respose && error.response.data ? error.response.data : error.response);

// eslint-disable-next-line max-len
instance.interceptors.response.use(({ data }) => data, (error) => {
  console.log(error, 'error api/config');
  if ((error.response && error.response.status === 401 && window.location.pathname !== '' && window.location.pathname !== '/') || (error.response && error.response.status === 404 && window.location.pathname !== '' && window.location.pathname !== '/')) {
    localStorage.removeItem('collicare-user-details');
    window.location.href = '/';
  }
  return Promise.reject(rejectedResponse(error));
});

export default instance;
